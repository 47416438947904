<template>
  <div class="sai-tag-choice" id="sai-tag-choice">
    <div class="sai-tag-choice__content">
      <div class="sai-tag-choice__content__index">
        <div class="sai-tag-choice__content__index__inner">
          <div
            class="sai-tag-choice__content__index__inner__vowel"
            v-for="(idx_vowel, idx) in displayIndexVowels"
            :key="idx"
          >
            <a
              :class="{ active: inScroll(idx) }"
              @click.prevent="scroll(idx)"
              >{{ idx_vowel }}</a
            >
          </div>
        </div>
      </div>
      <div id="sai-box-tag-lists" ref="sciTagLists">
        <div class="all_tag_wrapper">
          <div
            v-for="(idx_v, i) in indexVowels.kana"
            :key="i"
            :id="generateVowelIdx(idx_v)"
          >
            <div
              class="vowle_group"
              v-for="(v, id) in vowels[idx_v]"
              :key="id"
              v-show="topTags[v.kana] || isIndexVowels(v.kana)"
            >
              <div class="first_letter">{{ displayFirstLetter(v) }}</div>
              <a
                class="tag_content"
                v-for="item in displayTags(v)"
                :key="item.id"
                :class="{ disabled: isSelected(item) }"
                @click.prevent="selectTag(item)"
              >
                <span class="tag_name">
                  <span class="icon">#</span>
                  <span class="name">{{ item.text }}</span>
                </span>
                <span class="tag_icon">
                  <font-awesome-icon icon="plus" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TagChoiceMixin from '@/mixins/TagChoiceMixin';
import Component, { mixins } from 'vue-class-component';
import { eventBus } from '@/eventBus';

@Component({})
export default class TagChoice extends mixins(TagChoiceMixin) {
  async selectTag(tag) {
    await this.$store.dispatch('tagSearch/resetInput');
    await this.$store.dispatch('tagSearch/addSelectedTag', tag);
    // Use setShow for box
    this.$emit('setShow', true);

    if (!this.$store.state.user.isIphone) {
      setTimeout(() => {
        eventBus.$emit('selectNextTag');
      }, 0);
    }

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = await this.updateTicket(tag);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/tagChoice';
</style>
